<template>
  <el-form
    class="login-form"
    :rules="rules"
    :model="model"
    ref="form"
    :validate-on-rule-change="false"
  >
    <div class="row">
      <div class="col-sm-6">
        <el-form-item prop="first_name">
          <el-input
            v-model="model.first_name"
            :placeholder="$t('registerDetail.firstName')"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
      </div>
      <div class="col-sm-6">
        <el-form-item prop="last_name">
          <el-input
            v-model="model.last_name"
            :placeholder="$t('registerDetail.lastName')"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <el-form-item prop="email" :error="errors.email">
      <el-alert
        v-show="needLogin"
        type="error"
        :closable="false"
        style="margin-bottom:10px"
      >
        The email has already been taken,
        <a :href="'/login?email=' + model.email">Pelase click here to login</a>.
      </el-alert>
      <el-input
        v-model="model.email"
        :placeholder="$t('login.email')"
        prefix-icon="el-icon-message"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="model.password"
        :placeholder="$t('login.password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verifyPassword">
      <el-input
        v-model="model.verifyPassword"
        :placeholder="$t('login.verify_password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item v-if="model.referral_code !== ''" prop="referral_code">
      <el-input
        v-model="model.referral_code"
        prefix-icon="el-icon-paperclip"
        :disabled="true"
      ></el-input>
    </el-form-item>
    <el-form-item prop="captcha" :error="errors.captcha">
      <el-row>
        <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
          <el-image
            @click="refreshCaptcha"
            :src="captchaImg"
            fit="fill"
          ></el-image>
        </el-col>
        <el-col :xs="{ span: 24 }" :sm="{ span: 15, offset: 1 }">
          <el-input
            v-model="model.captcha"
            :placeholder="$t('login.captcha')"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item align="right">
      <el-button type="primary" block @click="handleRegister">{{
        $t("login.register")
      }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";

export default {
  mixins: [formMixin],
  data: () => {
    return {
      model: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        verifyPassword: "",
        captcha: "",
        referral_code: ""
      },
      needLogin: false,
      errors: {
        email: "",
        captcha: ""
      }
    };
  },
  computed: {
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      const checkEmail = (rule, value, callback) => {
        // 验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          callback(new Error(this.$t("register.please_valid_email")));
        } else {
          callback();
        }
      };

      return {
        first_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        email: [
          // { validator: checkEmail, trigger: "blur" },
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [{ validator: checkVerifyPassword, trigger: "blur" }],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ]
      };
    },
    ...mapGetters({
      captchaImg: "captcha/img",
      captchaKey: "captcha/key"
    })
  },

  mounted() {
    if (this.$route.query.referral_code) {
      this.model.referral_code = this.$route.query.referral_code;
    }
    this.$store.dispatch("captcha/getCaptcha");
  },

  methods: {
    ...mapActions("user", ["register"]),

    async handleRegister() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const {
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code
      } = this.model;
      const registerReq = {
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code
      };
      registerReq["captchaKey"] = this.captchaKey;

      try {
        await this.register(registerReq);
      } catch (error) {
        if (error.status === 422) {
          this.needLogin = true;
        }
        return error;
      }

      await this.$message({
        message: this.$t("message.register_success"),
        type: "success"
      });
      await this.$store.dispatch("user/getProfile");

      let nextRoutePath = localStorage.getItem("lastAccessRoutePath");
      localStorage.setItem("lastAccessRoutePath", "");
      if (!nextRoutePath || nextRoutePath == "/home") {
        this.$router.replace({ name: "SATList" });
      } else {
        this.$router.replace({ path: nextRoutePath });
      }
    },
    refreshCaptcha() {
      this.$store.dispatch("captcha/getCaptcha");
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          email: "",
          captcha: ""
        };
        this.needLogin = false;
      }
    }
  }
};
</script>
<style scoped>
.register-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #ff770c;
}

.clearfix {
  color: white;
}

.el-button {
  outline: none;
}
::v-deep .el-form-item__content {
  line-height: 2;
}
</style>
